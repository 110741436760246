import {eventConstants} from "../constants/actionTypes";

const initialState = {
    eventsList: [],
    eventDetails: null,
    eventImages: [],
    eventLogo: null,
    eventError: null,
    getEventsError: false,
    isLoading: false,
    organizations: [],
    categories: [],
    filterOrganizationId: null,
    organizationDetails: null,
    eventNotifications: [],
    notificationModel: null,
    order: "asc",
    orderBy: "name.fr",
    currentPage: 0,
    rowsPerPage: 10,
    viewId: undefined,
    basket: [],
    staff: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case eventConstants.SET_FILTER_ORGANIZATION_ID:
            return {
                ...state,
                filterOrganizationId: action.payload
            };
        case eventConstants.GET_LIST_EVENTS_BY_ORGANIZATION_REQUEST:
            return {
                ...state,
                eventsList: [],
                isLoading: true,
            };
        case eventConstants.GET_LIST_EVENTS_BY_ORGANIZATION_SUCCESS:
            return {
                ...state,
                eventsList: action.payload.eventslist,
                isLoading: false,
            };
        case eventConstants.GET_LIST_EVENTS_BY_ORGANIZATION_FAILURE:
            return {...state, getEventsError: true, filterOrganizationId: null};
        case eventConstants.GET_EVENTS_REQUEST:
            return {...state, eventList: [], filterOrganizationId: null, isLoading: true};
        case eventConstants.GET_EVENTS_SUCCESS:
            return {...state, eventsList: action.payload, filterOrganizationId: null, isLoading: false};
        case eventConstants.GET_EVENTS_FAILURE:
            return {...state, getEventsError: true};
        case eventConstants.GET_EVENT_REQUEST:
            return {...state, eventPhotos: [], eventDetails: null};
        case eventConstants.GET_EVENT_SUCCESS:
            const {event, eventImages, eventLogo} = action.payload;
            return {...state, eventDetails: event, eventImages, eventLogo, eventError: null};
        case eventConstants.GET_EVENT_FAILURE:
            return {...state, eventDetails: null, eventError: "Event not found"};
        case eventConstants.DELETE_IMAGE:
            const newImages = state.eventImages.map(image => {
                return image.key === action.payload ? {...image, deleted: true} : image;
            });
            return {...state, eventImages: newImages};
        case eventConstants.REMOVE_EVENT_DETAILS:
            return {...state, eventDetails: null, eventImages: [], eventLogo: null, eventError: null};
        case eventConstants.GET_ORGANIZATIONS_NAME_SUCCESS:
            return {
                ...state, organizations: action.payload
            };
        case eventConstants.GET_CATEGORIES_SUCCESS:
            return {...state, categories: action.payload};
        case eventConstants.ADD_NEW_IMAGE:
            return {...state, eventImages: [...state.eventImages, action.payload]};
        case eventConstants.GET_ORGANIZATION_NAME_BU_ID_SUCCESS:
            return {...state, organizationDetails: action.payload};
        case eventConstants.REMOVE_ORGANIZATION_DETAILS:
            return {...state, organizationDetails: null};
        case eventConstants.GET_EVENT_NOTIFICATIONS_SUCCESS:
            return {...state, eventNotifications: action.payload};
        case eventConstants.REMOVE_NOTIFICATIONS:
            return {...state, notificationModel: null, eventNotifications: []};
        case eventConstants.CLEAR_EVENT_FILTER:
            return {...state, filterOrganizationId: null};
        case eventConstants.SET_FILTER_VALUE:
            return {...state, filterOrganizationId: action.payload};
        case eventConstants.CHANGE_EVENT_ORDER:
            return {...state, order: action.payload};
        case eventConstants.CHANGE_EVENT_ORDERBY:
            return {...state, orderBy: action.payload};
        case eventConstants.CHANGE_EVENT_CURRENT_PAGE:
            return {...state, currentPage: action.payload};
        case eventConstants.CHANGE_EVENT_ROWS_PER_PAGE:
            return {...state, currentPage: 0, rowsPerPage: action.payload};
        case eventConstants.ONVIEW_EVENT:
            return {...state, viewId: action.payload};
        case eventConstants.ONVIEW_DONE_EVENT:
            return {...state, viewId: undefined};
        case eventConstants.GET_BASKET_REQUEST:
            return {...state, basket: []};
        case eventConstants.GET_BASKET_SUCCESS:
            return {...state, basket: action.payload};
        case eventConstants.GET_STAFF_REQUEST:
            return {...state, staff: []};
        case eventConstants.GET_STAFF_SUCCESS:
            return {...state, staff: action.payload};
        default:
            return state;
    }
};

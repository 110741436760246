export class OrganizationModel {
  constructor(
    address,
    category, // number /Foreign key to Categories collection
    description,
    /*
    descriptionAr,
    descriptionEn,
    descriptionEs,
    descriptionFr,
    */
    images,
    formEmail,
    receiveForm,
    //emailNotification,
    extension,
    facebook, //Page URL
    isPending,
    isActive,
    jobOffers, // page URL
    languages,
    /*
    languagesAr,
    languagesEn,
    languagesEs,
    languagesFr,
    */
    location, //array of numbers [-73.505559, 45.641611]
    logo,
    name,
    /*
    nameAr,
    nameEn,
    nameEs,
    nameFr,
    offeredServicesAr,
    offeredServicesEn,
    offeredServicesEs,
    offeredServicesFr,
    */
    offeredServices,
    phone,
    schedule,
    /*
    scheduleAr,
    scheduleEn,
    scheduleEs,
    scheduleFr,
    */
    suiteOffice,
    updatedDate,
    volunteerOffers, //Page URL
    website,
    /*
    websiteAr,
    websiteEn, //Page URL
    websiteEs,
    websiteFr, //Page URL    
    */
  ) {
    this.address = address;
    this.category = category;
    this.description = description;
    /*
    this.descriptionAr = descriptionAr;
    this.descriptionEn = descriptionEn;
    this.descriptionEs = descriptionEs;
    this.descriptionFr = descriptionFr;
    */
    this.images = images;
    this.formEmail = formEmail;
    this.receiveForm = receiveForm;
    //this.emailNotification = emailNotification;
    this.extension = extension;
    this.facebook = facebook;
    this.isPending = isPending;
    this.isActive = isActive;
    this.jobOffers = jobOffers;
    this.languages = languages;
    /*
    this.languagesAr = languagesAr;
    this.languagesEn = languagesEn;
    this.languagesEs = languagesEs;
    this.languagesFr = languagesFr;
    */
    this.location = location;
    this.logo = logo;
    this.name = name;
    /*
    this.nameAr = nameAr;
    this.nameEn = nameEn;
    this.nameEs = nameEs;
    this.nameFr = nameFr;
    this.offeredServicesEn = offeredServicesEn;
    this.offeredServicesFr = offeredServicesFr;
    this.offeredServicesEs = offeredServicesEs;
    this.offeredServicesAr = offeredServicesAr;
    */
    this.offeredServices = offeredServices;
    this.phone = phone;
    this.schedule = schedule;
    /*
    this.scheduleEn = scheduleEn;
    this.scheduleFr = scheduleFr;
    this.scheduleEs = scheduleEs;
    this.scheduleAr = scheduleAr;
    */
    this.suiteOffice = suiteOffice;
    this.updatedDate = updatedDate;
    this.volunteerOffers = volunteerOffers;
    this.website = website;
    /*
    this.websiteEn = websiteEn;
    this.websiteFr = websiteFr;
    this.websiteEs = websiteEs;
    this.websiteAr = websiteAr;
    */
  }
  
  toString() {
    return this.name;
  }
}

// Firestore data converter
export const OrganizationConverter = {
  toFirestore: function (model) {
    return {
      address: model.address,
      category: model.category, // number /Foreign key to Categories collection
      description: model.description,
      /*
      descriptionAr: model.descriptionAr,
      descriptionEn: model.descriptionEn,
      descriptionEs: model.descriptionEs,
      descriptionFr: model.descriptionFr,
      */
      images: model.images,
      formEmail: model.formEmail,
      receiveForm: model.receiveForm,
      //emailNotification: model.emailNotification,
      extension: model.extension,
      facebook: model.facebook, //Page URL
      isPending: model.isPending,
      isActive: model.isActive,
      jobOffers: model.jobOffers, // page URL
      languages: model.languages,
      /*
      languagesAr: model.languagesAr,
      languagesEn: model.languagesEn,
      languagesEs: model.languagesEs,
      languagesFr: model.languagesFr,
      */
      location: model.location, //array of numbers [-73.505559, 45.641611]
      logo: model.logo,
      name: model.name,
      /*
      nameAr: model.nameAr,
      nameEn: model.nameEn,
      nameEs: model.nameEs,
      nameFr: model.nameFr,
      offeredServicesAr: model.offeredServicesAr,
      offeredServicesEn: model.offeredServicesEn,
      offeredServicesEs: model.offeredServicesEs,
      offeredServicesFr: model.offeredServicesFr,
      */
      offeredServices: model.offeredServices,
      phone: model.phone,
      schedule: model.schedule,
      /*
      scheduleAr: model.scheduleAr,
      scheduleEn: model.scheduleEn,
      scheduleEs: model.scheduleEs,
      scheduleFr: model.scheduleFr,
      */
      suiteOffice: model.suiteOffice,
      updatedDate: model.updatedDate,
      volunteerOffers: model.volunteerOffers, //Page URL
      website: model.website,
      /*
      websiteAr: model.websiteAr,
      websiteEn: model.websiteEn, //Page URL
      websiteEs: model.websiteEs,
      websiteFr: model.websiteFr, //Page URL   
      */
    };
  },
  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);
    return new OrganizationModel(
      data.address,
      data.category, // number /Foreign key to Categories collection
      data.description,
      /*
      data.descriptionAr,
      data.descriptionEn,
      data.descriptionEs,
      data.descriptionFr,
      */
      data.images,
      data.formEmail,
      data.receiveForm,
      //data.emailNotification,
      data.extension,
      data.facebook, //Page URL
      data.isPending,
      data.isActive,
      data.jobOffers, // page URL
      data.languages,
      /*
      data.languagesAr,
      data.languagesEn,
      data.languagesEs,
      data.languagesFr,
      */
      data.location, //array of numbers [-73.505559, 45.641611]
      data.logo,
      data.name,
      /*
      data.nameAr,
      data.nameEn,
      data.nameEs,
      data.nameFr,
      data.offeredServicesAr,
      data.offeredServicesEn,
      data.offeredServicesEs,
      data.offeredServicesFr,
      */
      data.offeredServices,
      data.phone,
      data.schedule,
      /*
      data.scheduleAr,
      data.scheduleEn,
      data.scheduleEs,
      data.scheduleFr,
      */
      data.suiteOffice,
      data.updatedDate,
      data.volunteerOffers, //Page URL
      data.website,
      /*
      data.websiteAr,
      data.websiteEn, //Page URL
      data.websiteEs,
      data.websiteFr, //Page URL
      */
    );
  }
};

import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useDispatch} from "react-redux";

import {useTranslation} from "react-i18next";

import {modalActions} from "../../../redux/actions/modalAction";
import {deleteOrganization} from "../../../redux/actions/organizationAction";

export const DeleteOrganizationModal = ({element, order, orderBy, auth}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  
  const handleDelete = () => {
    dispatch(deleteOrganization(element.id, element.logo, element.images, orderBy, order, auth));
  };
  
  const handleClose = () => {
    dispatch(modalActions.showModal());
  };
  
  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Êtes-vous sûr de vouloir supprimer cet élément?"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete} color="primary" autoFocus>
            {t("agree")}
          </Button>
          <Button onClick={handleClose} color="primary">
            {t("disagree")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
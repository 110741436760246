function normalizedStringCompare(a = "", b = "") {
  // Useful with sort functions
  const normalizedA = getNormalizedString(a);
  const normalizedB = getNormalizedString(b);
  
  if (normalizedA === normalizedB)
    return 0;
  
  if (normalizedA > normalizedB)
    return -1;
  
  else
    return 1;
}


function twoStepsNormalizedStringCompare(
  stepOne = {a: "", b: ""},
  stepTwo = {a: "", b: ""},
  sortOrder
) {
  // Useful with sort functions
  // Double sorting conditions:
  // When stepOne has two equal strings, we instead compare stepTwo strings.
  //
  // For example:
  //             When stepOne.a === "hello" && stepOne.b === "hello"
  //             then use stepTwo strings
  if (stepOne.a === stepOne.b) {
    return sortOrder === "asc"
      ? normalizedStringCompare(stepTwo.a, stepTwo.b)
      : normalizedStringCompare(stepTwo.b, stepTwo.a);
  }
  
  return normalizedStringCompare(stepOne.a, stepOne.b);
}


function numberCompare(a, b) {
  // Useful with sort functions that need to compare numerical strings.
  return new Intl.Collator(undefined, {numeric: true}).compare(a, b);
  // Returns 0 when both numbers are equal, otherwise it's either -1 or 1
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator
}


function getNormalizedString(value) {
  if (typeof value !== "string")
    return value ? String(value) : ""; // return value as a string instance
  
  const cleanString = value.trim(); //"  abc " --> "abc"
  if (!cleanString)
    return "";
  
  // Normalize chars with accents like : é à --> e a
  // Also normalize wierd unicode chars like bolded unicode letters : 𝗔𝗹𝗶𝗺𝗲𝗻𝘁𝗮𝘁𝗶𝗼𝗻 --> Alimentation
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize#parameters
  // https://stackoverflow.com/a/37511463
  const stringWithoutAccents = cleanString.normalize("NFKD").replace(/\p{Diacritic}/gu, "");
  
  return stringWithoutAccents.toLowerCase();
}


function containsNormalizedSearchText(sourceText, searchText) {
  if (!sourceText && !searchText) {
    // When there's nothing to compare, always return true.
    return true;
  }
  
  if (!sourceText && searchText) {
    // When there is no sourceText and there is a searchText, always return false.
    return false;
  }
  
  if (!searchText) {
    // When there is a sourceText but no searchText, always return true.
    return true;
  }
  
  // Remove accents, useless leading whitespaces and wierd unicode chars from both strings before comparing .
  const normalizedSourceText = getNormalizedString(sourceText);
  const normalizedSearchText = getNormalizedString(searchText);
  
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/includes
  return Boolean(normalizedSourceText.includes(normalizedSearchText));
}


export {
  normalizedStringCompare,
  twoStepsNormalizedStringCompare,
  numberCompare,
  getNormalizedString,
  containsNormalizedSearchText
};
import uniqid from "uniqid";

import {eventConstants} from "../constants/actionTypes";
import {db, fieldValue, store} from "../../base";
import {history} from "../../helpers/history";
import {getDistanceStraightLineInKM} from "../../helpers/geoLocationHelpers.js";
import {normalizedStringCompare} from "../../helpers/stringHelpers";

export const getEventsSuccess = (events) => {
  return {
    type: eventConstants.GET_EVENTS_SUCCESS,
    payload: events
  };
};

const getEventsFailure = (err) => {
  return {
    type: eventConstants.GET_EVENTS_FAILURE,
    payload: err
  };
};

export const removeEventDetails = () => ({type: eventConstants.REMOVE_EVENT_DETAILS});

export const removeOrganizationDetails = () => ({type: eventConstants.REMOVE_ORGANIZATION_DETAILS});

const getEventRequest = () => ({type: eventConstants.GET_EVENT_REQUEST});

const getEventSuccess = (event, eventImages, eventLogo) => {
  return {
    type: eventConstants.GET_EVENT_SUCCESS,
    payload: {
      event,
      eventImages,
      eventLogo
    }
  };
};

const getEventFailure = (err) => {
  return {
    type: eventConstants.GET_EVENT_FAILURE,
    payload: err
  };
};

export const addNewPhoto = (data) => {
  return {
    type: eventConstants.ADD_NEW_IMAGE,
    payload: data
  };
};

const getOrganizationsNameSuccess = (data) => {
  return {
    type: eventConstants.GET_ORGANIZATIONS_NAME_SUCCESS,
    payload: data
  };
};

const getOrganizationNameByIdSuccess = (data) => {
  return {
    type: eventConstants.GET_ORGANIZATION_NAME_BU_ID_SUCCESS,
    payload: data
  };
};

const getCategoriesSuccess = (data) => {
  return {
    type: eventConstants.GET_CATEGORIES_SUCCESS,
    payload: data
  };
};

export const deleteEventImage = (name) => {
  return {
    type: eventConstants.DELETE_IMAGE,
    payload: name
  };
};

// export const saveEvent = (event) => {
//     return {
//         type: eventConstants.SAVE_EVENT_MODEL,
//         payload: event
//     };
// };

// export const saveNotification = (notification) => {
//     return {
//         type: eventConstants.SAVE_NOTIFICATION_MODEL,
//         payload: notification
//     }
// }

const getEventNotificationsSuccess = (data) => {
  return {
    type: eventConstants.GET_EVENT_NOTIFICATIONS_SUCCESS,
    payload: data
  };
};


export const deleteEventSuccess = () => {
  return {
    type: eventConstants.DELETE_EVENT_SUCCESS
  };
};

export const deleteEventFailure = (err) => {
  return {
    type: eventConstants.DELETE_EVENT_FAILURE,
    payload: err
  };
};

export const removeNotifications = () => {
  return {
    type: eventConstants.REMOVE_NOTIFICATIONS
  };
};

export const setFilterValue = (value) => {
  return {
    type: eventConstants.SET_FILTER_VALUE,
    payload: value
  };
};

export const clearEventFilter = () => {
  return {
    type: eventConstants.CLEAR_EVENT_FILTER
  };
};

export const getStaffSuccess = (data) => {
  return {
    type: eventConstants.GET_STAFF_SUCCESS,
    payload: data
  };
};

export const getStaffRequest = () => {
  return {
    type: eventConstants.GET_STAFF_REQUEST
  };
};

export const changeEventStatus = (event, filterOrganizationId) => dispatch => {
  return db
    .collection("Event")
    .doc(event.id)
    .set({isAvailable: !event.isAvailable}, {merge: true})
    .then(() => dispatch(getEvents(filterOrganizationId)))
    .catch(error => console.error("Error writing document: ", error));
};

export const removeEventImages = (images, eventId) => {
  if (images) {
    return images.forEach(imageName => {
      const name = imageName.key || imageName;
      if (typeof (name) !== "string")
        return;
      store
        .ref()
        .child(`images/event/${eventId}/${name}`)
        .delete()
        .then(() => console.log("remove success"))
        .catch((err) => {
        });
    });
  }
};

export const removeEventLogo = (logo, eventId) => {
  const name = logo.key || logo;
  return store
    .ref()
    .child(`images/event/${eventId}/logo/${name}`)
    .delete()
    .then(() => console.log("remove success"))
    .catch((err) => {
    });
  
};

const getEventsByIdOrganizationSuccess = (eventslist, filterOrganizationId) => {
  return {
    type: eventConstants.GET_LIST_EVENTS_BY_ORGANIZATION_SUCCESS,
    payload: {
      eventslist: eventslist, filterOrganizationId: filterOrganizationId
    }
  };
};

export const setFilterOrganizationId = filterOrganizationId => {
  return {
    type: eventConstants.SET_FILTER_ORGANIZATION_ID,
    payload: filterOrganizationId
  };
};

const getEventsByIdOrganizationFailure = (err) => {
  return {
    type: eventConstants.GET_LIST_EVENTS_BY_ORGANIZATION_FAILTURE,
    payload: err
  };
};

export const changeCurrentPage = currentPage => {
  return {
    type: eventConstants.CHANGE_EVENT_CURRENT_PAGE,
    payload: currentPage
  };
};

export const changeRowsPerPage = rowsPerPage => {
  return {
    type: eventConstants.CHANGE_EVENT_ROWS_PER_PAGE,
    payload: rowsPerPage
  };
};

export const getEventsRequest = () => {
  return {
    type: eventConstants.GET_EVENTS_REQUEST
  };
};

export const getEventsByIdOrganizationRequest = () => {
  return {
    type: eventConstants.GET_LIST_EVENTS_BY_ORGANIZATION_REQUEST
  };
};

export const getEventsByIdOrganization = (organizationId, auth) => async dispatch => {
  try {
    dispatch(getEventsByIdOrganizationRequest());
    const response = await db
      .collection("Event")
      .where("organizationId", "==", organizationId)
      .get();
    
    const eventsList = response.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    }));
    
    // eventsList.forEach(event => {
    //
    // });
    
    //eventsList.sort((a, b) => a.distance - b.distance)
    
    const organizationNames = await getOrganizationsName();
    let events = await Promise.all(eventsList.map(async event => {
      await db
        .collection("Staff")
        .where("organizationId", "==", event.organizationId)
        .where("isAvailable" /*isAvailableReceiveReferrals"*/, "==", true)
        .get()
        .then((querySnapshot) => {
          const data = querySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
          }));
          event.staff = data;
        })
        .catch((err) => {
          console.log("errget2: ", err);
        });
      
      if (auth && auth.location && auth.location.length === 2 && event && event.location && event.location.length === 2) {
        event.distance = getDistanceStraightLineInKM({
          fromLocationCoords: {
            latitude: auth.location[0],
            longitude: auth.location[1]
          },
          destinationLocationCoords: {
            latitude: event.location[0],
            longitude: event.location[1]
          },
        });
      }
      
      const objectWithName = organizationNames.find(organization => organization.id === event.organizationId);
      const organizationName = objectWithName ? objectWithName.name : "null";
      return {...event, organizationName};
    }));
    
    const activeOrganizations = organizationNames.filter(org => org.isActive);
    
    dispatch(getEventsByIdOrganizationSuccess(events, organizationId));
    dispatch(getOrganizationsNameSuccess(activeOrganizations));
  } catch (e) {
    dispatch(getEventsByIdOrganizationFailure(e));
  }
};

export const getEvents = (idOrganization = null, auth) => dispatch => {
  if (idOrganization) {
    dispatch(getEventsByIdOrganization(idOrganization, auth));
  } else {
    dispatch(getAllEvents(auth));
  }
};

export const getActiveEvents = (auth) => async dispatch => {
  try {
    dispatch(getEventsRequest());
    await db
      .collection("Event")
      .where("isAvailable", "==", true)
      .get()
      .then(async (querySnapshot) => {
        const eventsList = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }));
        
        const organizationNames = await getOrganizationsName();
        
        await Promise.all(eventsList.map(async (element) => {
          
          await db
            .collection("Staff")
            .where("organizationId", "==", element.organizationId)
            .where("isAvailable"/*isAvailableReceiveReferrals"*/, "==", true)
            .get()
            .then((querySnapshot) => {
              const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
              }));
              element.staff = data;
            })
            .catch((err) => {
              console.log("errget2: ", err);
            });
          
          if (auth && auth.location && auth.location.length === 2 && element && element.location && element.location.length === 2) {
            element.distance = getDistanceStraightLineInKM({
              fromLocationCoords: {
                latitude: auth.location[0],
                longitude: auth.location[1]
              },
              destinationLocationCoords: {
                latitude: element.location[0],
                longitude: element.location[1]
              },
            });
          }
        }));
        
        //eventsList.sort((a, b) => a.distance - b.distance)
        
        const events = eventsList.map(event => {
          const objectWithName = organizationNames.find(item => item.id === event.organizationId);
          const organizationName = objectWithName ? objectWithName.name : "null";
          return {...event, organizationName};
        });
        
        const activeOrganizations = organizationNames.filter(org => org.isActive);
        
        dispatch(getEventsSuccess(events));
        dispatch(getOrganizationsNameSuccess(activeOrganizations));
      });
  } catch (e) {
    dispatch(getEventsFailure(e));
  }
};


export const getAllEvents = (auth) => async dispatch => {
  try {
    dispatch(getEventsRequest());
    await db
      .collection("Event")
      .get()
      .then(async (querySnapshot) => {
        const eventsList = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }));
        
        const organizationNames = await getOrganizationsName();
        
        await Promise.all(eventsList.map(async (element) => {
          
          await db
            .collection("Staff")
            .where("organizationId", "==", element.organizationId)
            .where("isAvailable" /*isAvailableReceiveReferrals"*/, "==", true)
            .get()
            .then((querySnapshot) => {
              const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
              }));
              element.staff = data;
            })
            .catch((err) => {
              console.log("errget2: ", err);
            });
          if (auth && auth.location && auth.location.length === 2 && element && element.location && element.location.length === 2) {
            element.distance = getDistanceStraightLineInKM({
              fromLocationCoords: {
                latitude: auth.location[0],
                longitude: auth.location[1]
              },
              destinationLocationCoords: {
                latitude: element.location[0],
                longitude: element.location[1]
              },
            });
          }
        }));
        
        //eventsList.sort((a, b) => a.distance - b.distance)
        
        const events = eventsList.map(event => {
          const objectWithName = organizationNames.find(item => item.id === event.organizationId);
          const organizationName = objectWithName ? objectWithName.name : "null";
          return {...event, organizationName};
        });
        
        const activeOrganizations = organizationNames.filter(org => org.isActive);
        
        dispatch(getEventsSuccess(events));
        dispatch(getOrganizationsNameSuccess(activeOrganizations));
      });
  } catch (e) {
    dispatch(getEventsFailure(e));
  }
};

export const getOrganizationsName = async () => {
  try {
    const response = await db
      .collection("Organization")
      .orderBy("name")
      .get();
    
    return response.docs.map(doc =>
      ({
        name: doc.data().name.fr,
        id: doc.id,
        category: doc.data().category,
        isActive: doc.data().isActive,
      })
    );
  } catch (e) {
  }
};

export const getEvent = (eventId) => {
  return async dispatch => {
    dispatch(getEventRequest());
    try {
      const eventResponse = await db
        .collection("Event")
        .doc(eventId)
        .get();
      
      if (!eventResponse.exists) throw new Error();
      const data = eventResponse.data();
      const startDate = data.startDate.toDate();
      const endDate = data.endDate.toDate();
      
      const mapToImage = async (imageName, type) => {
        try {
          let url;
          if (type === "logo") {
            url = await store.ref().child(`images/event/${eventId}/logo/${imageName}`).getDownloadURL();
          } else {
            url = await store.ref().child(`images/event/${eventId}/${imageName}`).getDownloadURL();
          }
          return {key: imageName, url};
        } catch (e) {
        }
      };
      const urlResponse = await Promise.all(data.images.map(mapToImage));
      const urlLogo = await mapToImage(data.logo, "logo");
      
      const event = {...data, startDate, endDate};
      dispatch(getEventSuccess(event, urlResponse, urlLogo));
    } catch (e) {
      dispatch(getEventFailure(e));
    }
  };
};

export const deleteEvent = (event, organizationId = null) => async dispatch => {
  try {
    await db
      .collection("Event")
      .doc(event.id)
      .delete();
    await removeEventImages(event.images, event.id);
    await removeEventLogo(event.logo, event.id);
    
    await dispatch(deleteEventSuccess());
    await dispatch(getEvents(organizationId));
    history.goBack();//.push("/events");
  } catch (err) {
    await dispatch(deleteEventFailure(err));
  }
};

export const createEvent = async (event, notification) => {
  const eventRef = db.collection("Event").doc();
  const uploadImagesResult = uploadImages(event.images, eventRef.id);
  const logo = uploadLogo(event.logo, eventRef.id);
  const currentEvent = {...event, images: uploadImagesResult, logo: logo};
  if (notification.sendCreationNotif) {
    notification.type = "eventCreated";
    currentEvent.sendCreationNotif = notification.sendCreationNotif;
    await createEventModel(currentEvent, eventRef.id);
    await createNotification(event, eventRef.id, notification);
  } else {
    await createEventModel(currentEvent, eventRef.id);
  }
};

const createEventModel = (event, eventId) => {
  return db
    .collection("Event")
    .doc(eventId)
    .set(event)
    .then(result => {
      history.goBack();//.push("/events");
    })
    .catch(error => console.error("Error writing document: ", error));
  
};

export const updateEvent = async (event, eventImages, id, eventLogo, notification) => {
  const imagesForDelete = eventImages.filter(image => image !== undefined && image.deleted !== undefined);
  let countingImage = event.images;
  
  const otherImagesNew = countingImage.filter(image => {
    return image !== undefined && image !== null;
  });
  
  //remove old images from store
  if (imagesForDelete.length) {
    await removeEventImages(imagesForDelete, id);
  }
  //add new images in store
  let uploadImagesResult;
  if (otherImagesNew.length) {
    uploadImagesResult = await uploadImages(event.images, id);
  }
  
  if (event.logo !== eventLogo.key) {
    await removeEventLogo(eventLogo, id);
    event.logo = uploadLogo(event.logo, id);
  }
  
  const currentImages = eventImages.filter(item => item && !item.deleted && item.key !== undefined).map(img => img.key);
  const updatedArray = otherImagesNew.length ? currentImages.concat(uploadImagesResult) : currentImages;
  
  const currentEventModel = {...event, images: updatedArray};
  if (notification.sendCreationNotif) {
    notification.type = "eventModified";
    currentEventModel.sendCreationNotif = notification.sendCreationNotif;
    await createNotification(event, id, notification);
  }
  await updateEventModel(currentEventModel, id);
};

const updateEventModel = (event, id) => {
  return db
    .collection("Event")
    .doc(id)
    .set(event, {merge: true})
    .then(() => history.goBack()) //.push("/events"))
    .catch(error => console.error("Error writing document: ", error));
};

const uploadImages = (images, eventId) => {
  const newBlobNames = [];
  if (images) {
    images.forEach(img => {
      const name = uniqid(undefined, ".png");
      newBlobNames.push(name);
      store
        .ref()
        .child(`images/event/${eventId}/${name}`)
        .put(img)
        .then(() => console.log("images uploaded"))
        .catch(error => console.error("Error uploading file: ", error));
    });
  }
  return newBlobNames;
};

const uploadLogo = (image, eventId) => {
  const name = uniqid(undefined, ".png");
  store
    .ref()
    .child(`images/event/${eventId}/logo/${name}`)
    .put(image)
    .then(() => console.log("images uploaded"))
    .catch(error => console.error("Error uploading file: ", error));
  return name;
};

export const getActiveOrganizationsName = () => dispatch => {
  return db
    .collection("Organization")
    .where("isActive", "==", true)
    .get()
    .then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => ({
        name: doc.data().name.fr,
        category: doc.data().category,
        id: doc.id,
      }));
      
      data.sort((a, b) => normalizedStringCompare(b.name, a.name));
      dispatch(getOrganizationsNameSuccess(data));
    })
    .catch(err => {
    });
};

export const getStaffOrganizationsName = () => dispatch => {
  return db
    .collection("Organization")
    //.where("isActive", "==", true)
    .get()
    .then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => ({
        name: doc.data().name.fr,
        id: doc.id,
        category: doc.data().category
      }));
      
      data.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        nameA = nameA.localeCompare(nameB);
        nameB = nameB.localeCompare(nameA);
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      dispatch(getOrganizationsNameSuccess(data));
    })
    .catch(err => {
    });
};

export const getOrganizationById = (organizationId) => dispatch => {
  return db
    .collection("Organization")
    .doc(organizationId)
    .get()
    .then(querySnapshot => {
      const data = {
        name: querySnapshot.data().name.fr,
        id: querySnapshot.id
      };
      dispatch(getOrganizationNameByIdSuccess(data));
    })
    .catch(err => {
    });
};

export const getCategories = () => dispatch => {
  return db
    .collection("Category")
    .get()
    .then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => ({...doc.data()}));
      var sorted = data.sort(function (a, b) {
        return a.name.fr.localeCompare(b.name.fr);
      });
      dispatch(getCategoriesSuccess(sorted));
    })
    .catch(err => {
    });
};

const createNotification = ({category, organizationId, nameEn, nameFr}, eventId, notificationModel) => {
  const date = new Date();
  const notification = {
    categoryId: category,
    date,
    eventId,
    eventName: nameFr || nameEn,
    organizationId,
    title: notificationModel.title,
    message: notificationModel.message,
    type: notificationModel.type
  };
  return db
    .collection("Notification")
    .add(notification)
    .then(() => console.log(notificationModel.type))
    .catch(error => console.error("created notification err: ", error));
};

export const getEventNotifications = (eventId) => dispatch => {
  return db
    .collection("Notification")
    .where("eventId", "==", eventId)
    .get()
    .then((querySnapshot) => {
      const data = querySnapshot.docs.map(doc => ({
        title: doc.data().title,
        message: doc.data().message,
        date: doc.data().date.toDate()
      }));
      
      data.sort((a, b) => {
        var dateA = a.date;
        var dateB = b.date;
        
        if (dateA < dateB) {
          return 1;
        }
        if (dateA > dateB) {
          return -1;
        }
        return 0;
      });
      
      dispatch(getEventNotificationsSuccess(data));
    })
    .catch((err) => {
    });
};

export const changeOrder = (sortName) => {
  return {
    type: eventConstants.CHANGE_EVENT_ORDER,
    payload: sortName
  };
};

export const changeOrderBy = (sortName) => {
  return {
    type: eventConstants.CHANGE_EVENT_ORDERBY,
    payload: sortName
  };
};

export const onViewing = (data) => {
  return {
    type: eventConstants.ONVIEW_EVENT,
    payload: data
  };
};

export const onDoneViewing = () => {
  return {
    type: eventConstants.ONVIEW_DONE_EVENT,
  };
};

export const getBasketRequest = () => {
  return {
    type: eventConstants.GET_BASKET_REQUEST,
  };
};

export const getBasketSuccess = (data) => {
  return {
    type: eventConstants.GET_BASKET_SUCCESS,
    payload: data
  };
};

export const getBasket = (id) => dispatch => {
  getBasketRequest();
  return db
    .collection("Basket")
    .where("creatorOrgId", "==", id)
    //.where("status", "!=", "archived")
    .get()
    .then(async (querySnapshot) => {
      const data = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        client: []
      }));
      
      await Promise.all(data.map(async (element) => {
        await db
          .collection("Client")
          .doc(element.clientId)
          .get()
          .then((querySnapshot) => {
            const fdata = querySnapshot.data();
            element.client = fdata;
          })
          .catch((err) => {
            console.log("errr: ", err);
          });
        
        await db
          .collection("ReferralItem")
          .where("basketId", "==", element.id)
          .get()
          .then((querySnapshot) => {
            const rdata = querySnapshot.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
            }));
            element.referral = rdata;
          })
          .catch((err) => {
            console.log("errr: ", err);
          });
      }));
      dispatch(getBasketSuccess(data));
    })
    .catch((err) => {
    });
};

export const createBasket = (data) => dispatch => {
  return db
    .collection("Client")
    .add(data.clientData)
    .then(async (docRef) => {
      data.basketData.clientId = docRef.id;
      await db
        .collection("Basket")
        .add(data.basketData)
        .then((docRef) => {
          if (data && data.ReferralData) {
            data.ReferralData.basketId = docRef.id;
            dispatch(addReferral(data.ReferralData, data.auth));
          }
          
          if (data.type === "volOp") {
            let addToBasketData = {
              id: docRef.id,
              currVol: data.currId,
              organizationId: data.auth.organizationId,
            };
            dispatch(addToBasketVol(addToBasketData));
          } else if (data.type === "event") {
            let addToBasketData = {
              id: docRef.id,
              currEvent: data.currId,
              organizationId: data.auth.organizationId,
            };
            dispatch(addToBasket(addToBasketData));
          } else if (data.type === "org") {
            let addToBasketData = {
              id: docRef.id,
              currOrg: data.currId,
              organizationId: data.auth.organizationId,
            };
            dispatch(addToBasketOrg(addToBasketData));
          }
        })
        .catch(error => console.error("created basket err: ", error));
    })
    .catch(error => console.error("created client err: ", error));
};

export const addToBasket = (data) => dispatch => {
  return db
    .collection("Basket")
    .doc(data.id)
    .update({
      //comment: data.comment,
      eventList: fieldValue.arrayUnion(data.currEvent)
    })
    .then(() => dispatch(getBasket(data.organizationId)))
    .catch(error => console.error("Add to basket err: ", error));
};

export const addToBasketOrg = (data) => dispatch => {
  return db
    .collection("Basket")
    .doc(data.id)
    .update({
      //comment: data.comment,
      organizationList: fieldValue.arrayUnion(data.currOrg)
    })
    .then(() => dispatch(getBasket(data.organizationId)))
    .catch(error => console.error("Add to basket org err: ", error));
};

export const addToBasketVol = (data) => dispatch => {
  return db
    .collection("Basket")
    .doc(data.id)
    .update({
      //comment: data.comment,
      volunteerOpportunityList: fieldValue.arrayUnion(data.currVol)
    })
    .then(() => dispatch(getBasket(data.organizationId)))
    .catch(error => console.error("Add to basket vol err: ", error));
};

export const getStaffs = (orgId) => dispatch => {
  dispatch(getStaffRequest);
  return db
    .collection("Staff")
    .where("organizationId", "==", orgId)
    .where("isAvailable", "==", true)
    .orderBy("firstName", "asc")
    .get()
    .then((querySnapshot) => {
      const data = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }));
      
      dispatch(getStaffSuccess(data));
    })
    .catch((err) => {
      console.log("errr: ", err);
    });
};

export const addReferral = (data, auth) => dispatch => {
  return db
    .collection("ReferralItem")
    .add(data)
    .then(() => dispatch(getBasket(auth.organizationId)))
    .catch(error => console.error("addReferral err: ", error));
};

export const editReferral = (data, auth, id) => dispatch => {
  return db
    .collection("ReferralItem")
    .doc(id)
    .update(data)
    .then(() => dispatch(getBasket(data.organizationId)))
    .catch(error => console.error("editReferral err: ", error));
};
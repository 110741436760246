import {combineReducers} from "redux";

import authReducer from "./authReducer";
import eventReducer from "./eventReducer";
import organizationReducer from "./organizationReducer";
import modalReducer from "./ModalReducer";
import staffReducer from "./staffReducer";
import basketReducer from "./basketReducer";
import reportReducer from "./reportReducer";
import volunteerReducer from "./volunteerReducer";

const appReducer = combineReducers({
    auth: authReducer,
    event: eventReducer,
    organization: organizationReducer,
    modal: modalReducer,
    staff: staffReducer,
    basket: basketReducer,
    report: reportReducer,
    volunteer: volunteerReducer,
});

export default (state, action) => {
    if (action.type === "LOGOUT_SUCCESS") {
        return appReducer(undefined, action);
    }
    
    return appReducer(state, action);
};

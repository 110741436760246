export const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  
  VERIFY_REQUEST: "VERIFY_REQUEST",
  VERIFY_SUCCESS: "VERIFY_SUCCESS",
  
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILED: "FORGOT_PASSWORD_FAILED",
};


export const eventConstants = {
  GET_EVENTS_REQUEST: "GET_EVENTS_REQUEST",
  GET_EVENTS_SUCCESS: "GET_EVENTS_SUCCESS",
  GET_EVENTS_FAILURE: "GET_EVENTS_FAILURE",
  
  GET_EVENT_REQUEST: "GET_EVENT_REQUEST",
  GET_EVENT_SUCCESS: "GET_EVENT_SUCCESS",
  GET_EVENT_FAILURE: "GET_EVENT_FAILURE",
  
  DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",
  DELETE_EVENT_FAILURE: "DELETE_EVENT_FAILURE",
  
  REMOVE_EVENT_DETAILS: "REMOVE_EVENT_DETAILS",
  
  DELETE_IMAGE: "DELETE_IMAGE",
  
  GET_ORGANIZATIONS_NAME_SUCCESS: "GET_ORGANIZATIONS_NAME_SUCCESS",
  GET_ORGANIZATIONS_NAME_FAILURE: "GET_ORGANIZATIONS_NAME_FAILURE",
  
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_FAILURE: "GET_CATEGORIES_FAILURE",
  
  GET_LIST_EVENTS_BY_ORGANIZATION_REQUEST: "GET_LIST_EVENTS_BY_ORGANIZATION_REQUEST",
  GET_LIST_EVENTS_BY_ORGANIZATION_SUCCESS: "GET_LIST_EVENTS_BY_ORGANIZATION_SUCCESS",
  GET_LIST_EVENTS_BY_ORGANIZATION_FAILURE: "GET_LIST_EVENTS_BY_ORGANIZATION_FAILURE",
  SET_FILTER_ORGANIZATION_ID: "SET_FILTER_ORGANIZATION_ID",
  GET_ORGANIZATION_NAME_BU_ID_SUCCESS: "GET_ORGANIZATION_NAME_BU_ID_SUCCESS",
  REMOVE_ORGANIZATION_DETAILS: "REMOVE_ORGANIZATION_DETAILS",
  
  ADD_NEW_IMAGE: "ADD_NEW_IMAGE",
  
  SAVE_EVENT_MODEL: "SAVE_EVENT_MODEL",
  
  GET_EVENT_NOTIFICATIONS_SUCCESS: "GET_EVENT_NOTIFICATIONS_SUCCESS",
  
  SAVE_NOTIFICATION_MODEL: "SAVE_NOTIFICATION_MODEL",
  
  REMOVE_NOTIFICATIONS: "REMOVE_NOTIFICATIONS",
  
  SET_FILTER_VALUE: "SET_FILTER_VALUE",
  CLEAR_EVENT_FILTER: "CLEAR_EVENT_FILTER",
  
  CHANGE_EVENT_ORDER: "CHANGE_EVENT_ORDER",
  CHANGE_EVENT_ORDERBY: "CHANGE_EVENT_ORDERBY",
  
  CHANGE_EVENT_CURRENT_PAGE: "CHANGE_EVENT_CURRENT_PAGE",
  CHANGE_EVENT_ROWS_PER_PAGE: "CHANGE_EVENT_ROWS_PER_PAGE",
  
  ONVIEW_EVENT: "ONVIEW_EVENT",
  ONVIEW_DONE_EVENT: "ONVIEW_DONE_EVENT",
  
  GET_BASKET_REQUEST: "GET_BASKET_REQUEST",
  GET_BASKET_SUCCESS: "GET_BASKET_SUCCESS",
  
  GET_STAFF_REQUEST: "GET_STAFF_REQUEST",
  GET_STAFF_SUCCESS: "GET_STAFF_SUCCESS",
  
};


export const orgConstants = {
  CREATE_ORGANIZATION: "CREATE_ORGANIZATION",
  GET_LIST_ORGANIZATIONS: "GET_LIST_ORGANIZATIONS",
  GET_LIST_ORGANIZATIONS_SUCCESS: "GET_LIST_ORGANIZATIONS_SUCCESS",
  
  GET_LIST_ACTIVE_ORGANIZATIONS: "GET_LIST_ACTIVE_ORGANIZATION",
  GET_LIST_ACTIVE_ORGANIZATIONS_SUCCESS: "GET_LIST_ACTIVE_ORGANIZATION_SUCCESS",
  
  GET_ORGANIZATION_BY_ID_SUCCESS: "GET_ORGANIZATION_BY_ID_SUCCESS",
  GET_ORGANIZATION_BY_ID_FAILURE: "GET_ORGANIZATION_BY_ID_FAILURE",
  
  SET_CURRENT_ORGANIZATION: "SET_CURRENT_ORGANIZATION",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  
  CHANGE_CURRENT_PAGE: "CHANGE_CURRENT_PAGE",
  CHANGE_ROWS_PER_PAGE: "SET_ROWS_PER_PAGE",
  CHANGE_ORDER: "CHANGE_ORDER",
  CHANGE_ORDERBY: "CHANGE_ORDERBY",
  
  DELETE_ORGANIZATION_SUCCESS: "DELETE_ORGANIZATION_SUCCESS",
  DELETE_ORGANIZATION_FAILURE: "DELETE_ORGANIZATION_FAILURE",
  
  SEARCH_ORGANIZATION: "SEARCH_ORGANIZATION",
  
  ONVIEW_ORGANIZATION: "ONVIEW_ORGANIZATION",
  ONVIEW_DONE_ORGANIZATION: "ONVIEW_DONE_ORGANIZATION"
};


export const modalConstants = {
  SHOW_MODAL: "SHOW_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL"
};


export const staffConstants = {
  FETCH_STAFF: "FETCH_STAFF",
  FETCH_STAFF_SUCCESS: "FETCH_STAFF_SUCCESS",
  FETCH_PROFILE: "FETCH_PROFILE",
  FETCH_PROFILE_SUCCESS: "FETCH_PROFILE_SUCCESS",
  ADD_STAFF: "ADD_STAFF",
  UPDATE_STAFF_SUCCESS: "UPDATE_STAFF_SUCCESS",
  DELETE_STAFF_SUCCESS: "DELETE_STAFF_SUCCESS",
  CHANGE_STAFF_CURRENT_PAGE: "CHANGE_STAFF_CURRENT_PAGE",
  CHANGE_STAFF_ROWS_PER_PAGE: "CHANGE_STAFF_ROWS_PER_PAGE",
  CHANGE_STAFF_ORDER: "CHANGE_STAFF_ORDER",
  CHANGE_STAFF_ORDERBY: "CHANGE_STAFF_ORDERBY",
  ERROR_STAFF: "ERROR_STAFF",
};


export const basketConstants = {
  FETCH_BASKET: "FETCH_BASKET",
  FETCH_BASKET_SUCCESS: "FETCH_BASKET_SUCCESS",
  FETCH_REFERRALS: "FETCH_REFERRALS",
  FETCH_REFERRALS_SUCCESS: "FETCH_REFERRALS_SUCCESS",
  CHANGE_BASKET_CURRENT_PAGE: "CHANGE_BASKET_CURRENT_PAGE",
  CHANGE_BASKET_ROWS_PER_PAGE: "CHANGE_BASKET_ROWS_PER_PAGE",
  CHANGE_BASKET_ORDER: "CHANGE_BASKET_ORDER",
  CHANGE_BASKET_ORDERBY: "CHANGE_BASKET_ORDERBY",
  CHANGE_REFERRALS_CURRENT_PAGE: "CHANGE_REFERRALS_CURRENT_PAGE",
  CHANGE_REFERRALS_ROWS_PER_PAGE: "CHANGE_REFERRALS_ROWS_PER_PAGE",
  CHANGE_REFERRALS_ORDER: "CHANGE_REFERRALS_ORDER",
  CHANGE_REFERRALS_ORDERBY: "CHANGE_REFERRALS_ORDERBY",
  GET_EVENTSTAFF_REQUEST: "GET_EVENTSTAFF_REQUEST",
  GET_EVENTSTAFF_SUCCESS: "GET_EVENTSTAFF_SUCCESS",
};


export const volunteerConstants = {
  FETCH_VOLUNTEER: "FETCH_VOLUNTEER",
  FETCH_VOLUNTEER_SUCCESS: "FETCH_VOLUNTEER_SUCCESS",
  SAVE_VOLUNTEER: "SAVE_VOLUNTEER",
  SAVE_VOLUNTEER_SUCCESS: "SAVE_VOLUNTEER_SUCCESS",
  CHANGE_VOLUNTEER_CURRENT_PAGE: "CHANGE_VOLUNTEER_CURRENT_PAGE",
  CHANGE_VOLUNTEER_ROWS_PER_PAGE: "CHANGE_VOLUNTEER_ROWS_PER_PAGE",
  CHANGE_VOLUNTEER_ORDER: "CHANGE_VOLUNTEER_ORDER",
  CHANGE_VOLUNTEER_ORDERBY: "CHANGE_VOLUNTEER_ORDERBY",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  ONVIEW_VOLUNTEER: "ONVIEW_VOLUNTEER",
  ONVIEW_DONE_VOLUNTEER: "ONVIEW_DONE_VOLUNTEER",
};


export const reportConstants = {
  
  FETCH_ORGANIZATION: "FETCH_ORGANIZATION",
  FETCH_ORGANIZATION_SUCCESS: "FETCH_ORGANIZATION_SUCCESS",
  CHANGE_ORGANIZATION_CURRENT_PAGE: "CHANGE_ORGANIZATION_CURRENT_PAGE",
  CHANGE_ORGANIZATION_ROWS_PER_PAGE: "CHANGE_ORGANIZATION_ROWS_PER_PAGE",
  CHANGE_ORGANIZATION_ORDER: "CHANGE_ORGANIZATION_ORDER",
  CHANGE_ORGANIZATION_ORDERBY: "CHANGE_ORGANIZATION_ORDERBY",
  
  FETCH_EVENT: "FETCH_EVENT",
  FETCH_EVENT_SUCCESS: "FETCH_EVENT_SUCCESS",
  CHANGE_EVENT_CURRENT_PAGE: "CHANGE_EVENT_CURRENT_PAGE",
  CHANGE_EVENT_ROWS_PER_PAGE: "CHANGE_EVENT_ROWS_PER_PAGE",
  CHANGE_EVENT_ORDER: "CHANGE_EVENT_ORDER",
  CHANGE_EVENT_ORDERBY: "CHANGE_EVENT_ORDERBY",
  
  FETCH_VOLUNTEER_OFFERS: "FETCH_VOLUNTEER_OFFERS",
  FETCH_VOLUNTEER_OFFERS_SUCCESS: "FETCH_VOLUNTEER_OFFERS_SUCCESS",
  CHANGE_VOLUNTEER_OFFERS_CURRENT_PAGE: "CHANGE_VOLUNTEER_OFFERS_CURRENT_PAGE",
  CHANGE_VOLUNTEER_OFFERS_ROWS_PER_PAGE: "CHANGE_VOLUNTEER_OFFERS_ROWS_PER_PAGE",
  CHANGE_VOLUNTEER_OFFERS_ORDER: "CHANGE_VOLUNTEER_OFFERS_ORDER",
  CHANGE_VOLUNTEER_OFFERS_ORDERBY: "CHANGE_VOLUNTEER_OFFERS_ORDERBY",
  
  FETCH_STAFF_REPORT: "FETCH_STAFF_REPORT",
  FETCH_STAFF_REPORT_SUCCESS: "FETCH_STAFF_REPORT_SUCCESS",
  CHANGE_STAFF_REPORT_CURRENT_PAGE: "CHANGE_STAFF_REPORT_CURRENT_PAGE",
  CHANGE_STAFF_REPORT_ROWS_PER_PAGE: "CHANGE_STAFF_REPORT_ROWS_PER_PAGE",
  CHANGE_STAFF_REPORT_ORDER: "CHANGE_STAFF_REPORT_ORDER",
  CHANGE_STAFF_REPORT_ORDERBY: "CHANGE_STAFF_REPORT_ORDERBY",
  
};
import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {logOut} from "../../redux/actions/authAction";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import {useTranslation} from "react-i18next";


const isDev = process.env.REACT_APP_ENV === "dev";


const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


export const Header = () => {
  const classes = useStyles();
  
  const {t} = useTranslation();
  const history = useHistory();
  
  const dispatch = useDispatch();
  const isAuthorized = useSelector(state => state.auth.isAuthenticated);
  const auth = useSelector(state => state.auth.user);
  
  const [menuClicked, setMenuClicked] = useState(false);
  
  const avatarButtonRef = useRef(null);
  
  const logout = () => {
    dispatch(logOut());
    handleClose();
  };
  
  const handleMenuClick = () => {
    setMenuClicked(true);
  };
  
  const handleProfileClick = () => {
    history.push("/staffs/myProfile");
    handleClose();
  };
  
  const handleClose = () => {
    setMenuClicked(false);
  };
  
  // Logged in Profile avatar component
  const AuthorizedAvatar = () => {
    return (
      <div>
        <Button color="inherit" onClick={handleMenuClick}>
          {auth.organizationId === "" && <span style={{marginRight: "1em"}}>Admin</span>}
          <Avatar ref={avatarButtonRef} />
        </Button>
      </div>
    );
  };
  
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {/*  Title */}
        <Typography variant="h6" className={classes.title}>
          {t("cms")}
          {isDev && " - Dev Beta"}
        </Typography>
        
        {/*  The clickable avatar button */}
        {isAuthorized && AuthorizedAvatar()}
        
        {/* Only visible when AuthorizedAvatar is clicked */}
        <Menu
          id="simple-menu"
          getContentAnchorEl={null}
          anchorEl={avatarButtonRef.current}
          keepMounted
          open={Boolean(menuClicked)}
          onClose={handleClose}
          elevation={2}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {auth && auth.organizationId &&
            <MenuItem onClick={handleProfileClick}>
              <PersonIcon />
              Profil
            </MenuItem>
          }
          <MenuItem onClick={logout}>
            <ExitToAppIcon />
            Déconnexion
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};


import {orgConstants} from "../constants/actionTypes";
import {db, store} from "../../base";
import {OrganizationConverter} from "../../components/organizations/OrganizationModel";
import {history} from "../../helpers/history";
import {getDistanceStraightLineInKM} from "../../helpers/geoLocationHelpers.js";

export const updateAllFieldOrganization = (idDoc, model) => dispatch => {
  const newModelPending = {
    ...model,
    isPending: model.isPending === undefined ? true : model.isPending
  };
  const newModelActive = {
    ...newModelPending,
    isActive: model.isActive === undefined ? false : model.isActive
  };
  db.collection("Organization")
    .doc(idDoc)
    .withConverter(OrganizationConverter)
    .set(newModelActive)
    .then((data) => {
      history.push("/organizations");
      // dispatch();
      //TO DO message
    })
    .catch(error => {
    });
};

export const updateFieldByNameOrganization = (
  idDoc,
  value,
  order,
  orderBy,
  auth
) => dispatch => {
  db.collection("Organization")
    .doc(idDoc)
    .set(value, {merge: true})
    .then(() => {
      dispatch(getListOrganizations(auth));
    })
    .catch(error => {
    });
};

export const getCategories = () => dispatch => {
  return db
    .collection("Category")
    //.orderBy("name.fr", "asc")  
    .get()
    .then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => ({...doc.data()}));
      var sorted = data.sort(function (a, b) {
        return a.name.fr.localeCompare(b.name.fr);
      });
      dispatch(getCategoriesSuccess(sorted));
    })
    .catch(err => {
    });
};

const getCategoriesSuccess = data => {
  return {
    type: orgConstants.GET_CATEGORIES_SUCCESS,
    payload: data
  };
};

async function uploadImages(idDoc, images, isLogo) {
  const imageName = await Promise.all(
    images.map(img => {
      return uploadImage(idDoc, img.name, img.file, isLogo);
    })
  );
  return imageName;
}

async function uploadImage(idDoc, imageName, file, isLogo) {
  try {
    const response = await store
      .ref()
      .child(
        `images/organization/${idDoc}${isLogo ? "/logo/" : "/"}${imageName}`
      )
      .put(file);
    return response.metadata.name;
  } catch (e) {
    console.log(e, "Error uploading image");
  }
}

export const createOrganization = (model, logoImage, otherImages) => {
  return async dispatch => {
    let orgRef = db.collection("Organization").doc();
    let logoName = "";
    let otherImagesName = [];
    if (model.logo.length) {
      logoName = await uploadImage(
        orgRef.id,
        model.logo,
        logoImage[0].file,
        true
      );
    }
    if (otherImages.length) {
      otherImagesName = await uploadImages(orgRef.id, otherImages, false);
    }
    const currentModel = {
      ...model,
      logo: logoName,
      descriptionImages: otherImagesName
    };
    dispatch(updateAllFieldOrganization(orgRef.id, currentModel));
  };
};
export const updateOrganization = (id, model, logoImages, otherImages) => {
  return async dispatch => {
    const imagesLogoForDelete = logoImages.filter(image => image.isDelete);
    const imagesLogo = logoImages.filter(
      image => !image.isDelete && !image.isStorage
    );
    const otherImagesForDelete = otherImages.filter(
      image => image !== undefined && image.isDelete
    );
    
    const otherImagesNew = otherImages.filter(image => {
      return image !== undefined && !image.isDelete && !image.isStorage;
    });
    
    if (imagesLogoForDelete.length) {
      await removeImages(id, imagesLogoForDelete, true);
    }
    
    if (otherImagesForDelete.length) {
      await removeImages(id, otherImagesForDelete, false);
    }
    
    if (model.logo.length && imagesLogo.length) {
      await uploadImage(
        id,
        model.logo,
        imagesLogo[0].file,
        true
      );
    }
    if (otherImagesNew.length) {
      await uploadImages(id, otherImagesNew, false);
    }
    dispatch(updateAllFieldOrganization(id, model));
  };
};

export const deleteOrganization = (
  idDoc,
  logoName,
  images,
  orderBy,
  order,
  auth
) => {
  return async dispatch => {
    let imagesLogoForDelete = [{name: logoName}];
    let imagesOtherForDelete = images.map(img => {
      return {name: img};
    });
    
    await removeImages(idDoc, imagesLogoForDelete, true).catch(error => {
      console.error("Error delete file: ", error);
    });
    await removeImages(idDoc, imagesOtherForDelete, false).catch(error => {
      console.error("Error delete file: ", error);
    });
    
    dispatch(deleteAllFieldOrganization(idDoc, orderBy, order, auth));
    
  };
};

export const deleteOrganizationSuccess = () => {
  return {
    type: orgConstants.DELETE_ORGANIZATION_SUCCESS
  };
};

export const deleteAllFieldOrganization = (idDoc, orderBy, order, auth) => async dispatch => {
  try {
    await db
      .collection("Organization")
      .doc(idDoc)
      .delete();
    await dispatch(deleteOrganizationSuccess());
    await dispatch(getListOrganizations(auth));
    history.push("/organizations");
  } catch (error) {
    console.error("Error removing document: ", error);
  }
};

async function removeImage(idDoc, image, isLogo) {
  if (image.name === "") {
    return;
  }
  return store
    .ref()
    .child(
      `images/organization/${idDoc}${isLogo ? "/logo/" : "/"}${image.name}`
    )
    .delete();
}

async function removeImages(idDoc, images, isLogo) {
  Promise.all(
    images.map(img => {
      return removeImage(idDoc, img, isLogo);
    })
  );
}

export const getListOrganizations = (
  auth
) => dispatch => {
  dispatch({
    type: orgConstants.GET_LIST_ORGANIZATIONS,
  });
  db.collection("Organization")
    //.orderBy(sortField, typeSort)
    .get()
    .then(async querySnapshot => {
      const orgList = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));
      
      /*
      if (!navigator.geolocation) {
          alert("La 'Geolocation' n'est pas prise en charge par votre navigateur.")
      } else {
          navigator.geolocation.getCurrentPosition(function (position) {
              tempLocation = [position.coords.latitude, position.coords.longitude]
          }, () => {
              alert("Impossible de récupérer votre emplacement. Veuillez autoriser l'accès à votre emplacement.")
          });
      }
      */
      
      await Promise.all(orgList.map(async (element) => {
        
        await db
          .collection("Staff")
          .where("organizationId", "==", element.id)
          .where("isAvailable" /*isAvailableReceiveReferrals"*/, "==", true)
          .get()
          .then((querySnapshot) => {
            const data = querySnapshot.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
            }));
            element.staff = data;
          })
          .catch((err) => {
            console.log("errget2: ", err);
          });
        
        if (auth && auth.location && auth.location.length === 2 && element && element.location && element.location.length === 2) {
          element.distance = getDistanceStraightLineInKM({
            fromLocationCoords: {
              latitude: auth.location[0],
              longitude: auth.location[1]
            },
            destinationLocationCoords: {
              latitude: element.location[0],
              longitude: element.location[1]
            },
          });
        }
      }));
      
      dispatch({
        type: orgConstants.GET_LIST_ORGANIZATIONS_SUCCESS,
        payload: orgList
      });
    })
    .catch(function (error) {
      console.error("Error get document: ", error);
    });
};

export const getListActiveOrganizations = (
  auth
) => dispatch => {
  dispatch({
    type: orgConstants.GET_LIST_ACTIVE_ORGANIZATIONS,
  });
  db.collection("Organization")
    .where("isPending", "==", false)
    .where("isActive", "==", true)
    .get()
    .then(async querySnapshot => {
      const orgList = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));
      
      /*
      if (!navigator.geolocation) {
          alert("La 'Geolocation' n'est pas prise en charge par votre navigateur.")
      } else {
          navigator.geolocation.getCurrentPosition(function (position) {
              tempLocation = [position.coords.latitude, position.coords.longitude]
          }, () => {
              alert("Impossible de récupérer votre emplacement. Veuillez autoriser l'accès à votre emplacement.")
          });
      }
      */
      
      await Promise.all(orgList.map(async (element) => {
        
        await db
          .collection("Staff")
          .where("organizationId", "==", element.id)
          .where("isAvailable" /*isAvailableReceiveReferrals"*/, "==", true)
          .get()
          .then((querySnapshot) => {
            const data = querySnapshot.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
            }));
            element.staff = data;
          })
          .catch((err) => {
            console.log("errget2: ", err);
          });
        
        if (auth && auth.location && auth.location.length === 2 && element && element.location && element.location.length === 2) {
          element.distance = getDistanceStraightLineInKM({
            fromLocationCoords: {
              latitude: auth.location[0],
              longitude: auth.location[1]
            },
            destinationLocationCoords: {
              latitude: element.location[0],
              longitude: element.location[1]
            },
          });
        }
      }));
      
      dispatch({
        type: orgConstants.GET_LIST_ACTIVE_ORGANIZATIONS_SUCCESS,
        payload: orgList
      });
    })
    .catch(function (error) {
      console.error("Error get document: ", error);
    });
};

async function getImage(imageName, id, isLogo) {
  try {
    const url = await store
      .ref()
      .child(`images/organization/${id}${isLogo ? "/logo/" : "/"}${imageName}`)
      .getDownloadURL();
    return {name: imageName, url};
  } catch (e) {
    console.log("failed to get image");
  }
}

const getImageModel = urlResponse => {
  if (urlResponse === undefined) return;
  return {
    name: urlResponse.name,
    url: urlResponse.url,
    isStorage: true,
    isDelete: false
  };
};

export const getOrganizationById = docId => {
  return async dispatch => {
    try {
      let orgRef = await db
        .collection("Organization")
        .doc(docId)
        .get();
      
      if (!orgRef.exists) throw new Error();
      
      const data = orgRef.data();
      
      const urlLogoResponse = await getImage(data.logo, docId, true); //await getImage(data.logo);
      let image = getImageModel(urlLogoResponse);
      let logoImage = image !== undefined ? [image] : [];
      
      const urls = await Promise.all(
        data.images.map(data => {
          return getImage(data, docId, false);
        })
      );
      let otherImages = urls.map(url => {
        return getImageModel(url);
      });
      
      let modelOrganization = {...data, logoImage, otherImages,};
      
      dispatch(getOrganizationSuccess(modelOrganization));
    } catch (e) {
      dispatch(getOrganizationFailure(e));
    }
  };
};

const getOrganizationSuccess = data => {
  return {
    type: orgConstants.GET_ORGANIZATION_BY_ID_SUCCESS,
    payload: data
  };
};

const getOrganizationFailure = err => {
  return {
    type: orgConstants.GET_ORGANIZATION_BY_ID_FAILURE,
    payload: err
  };
};

export const setCurrentOrganization = currentOrganization => {
  return {
    type: orgConstants.SET_CURRENT_ORGANIZATION,
    payload: currentOrganization
  };
};

export const changeCurrentPage = (page) => {
  return {
    type: orgConstants.CHANGE_CURRENT_PAGE,
    payload: page
  };
};

export const changeRowsPerPage = (count) => {
  return {
    type: orgConstants.CHANGE_ROWS_PER_PAGE,
    payload: count
  };
};

export const changeOrder = (sortName) => {
  return {
    type: orgConstants.CHANGE_ORDER,
    payload: sortName
  };
};

export const changeOrderBy = (sortName) => {
  return {
    type: orgConstants.CHANGE_ORDERBY,
    payload: sortName
  };
};

export const set_search_text = (searchText) => {
  return {
    type: orgConstants.SEARCH_ORGANIZATION,
    payload: searchText
  };
};

export const onViewing = (data) => {
  return {
    type: orgConstants.ONVIEW_ORGANIZATION,
    payload: data
  };
};

export const onDoneViewing = () => {
  return {
    type: orgConstants.ONVIEW_DONE_ORGANIZATION,
  };
};
import React, {lazy, Suspense} from "react";
import {Route, Router, Switch} from "react-router-dom";
import {useSelector} from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import {makeStyles} from "@material-ui/core/styles";

import {PublicRoute} from "./PublicRoute";
import {PrivateRoute} from "./PrivateRoute";
import {Header} from "../components/layout/Header";
import {history} from "../helpers/history";
import {ModalRoot} from ".././components/common/Modals/ModalRoot";
import LoadingOverlay from "../components/common/LoadingOverlay";

const MainLayout = lazy(() => import("../components/layout/Layout"));
const SignIn = lazy(() => import("../components/auth/SignIn"));
const ForgotPassword = lazy(() => import("../components/auth/ForgotPassword"));

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: "0",
    left: "0",
    top: "0",
    right: "0"
  }
}));

export const AppRoute = () => {
  const classes = useStyles();
  const isLoading = useSelector(state => state.auth.isLoading);
  
  if (isLoading) {
    return (<LoadingOverlay infoText="Chargement de l'authentification"/>);
  }
  
  return (
    <Router history={history}>
      <div className={classes.root}>
        <CssBaseline/>
        <Header/>
        <Suspense fallback={null}>
          <Switch>
            <Route exact path="/forgotpassword" component={ForgotPassword}/>
            <PublicRoute path="/login" component={SignIn}/>
            <PrivateRoute path="/" component={MainLayout}/>
          </Switch>
        </Suspense>
      </div>
      <ModalRoot/>
    </Router>
  );
};

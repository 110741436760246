import { authConstants } from "../constants/actionTypes";
import { auth, db } from "../../base";

const loginRequest = () => ({ type: authConstants.LOGIN_REQUEST });
const loginSuccess = (user) => {
    return {
        type: authConstants.LOGIN_SUCCESS,
        payload: {
            name: user.displayName,
            email: user.email,
            uid: user.uid,
            staffId: user.staffId,
            organizationId: user.organizationId,
            manageStaff: user.manageStaff,
            updateOrganizationEvent: user.updateOrganizationEvent,
            updateOrganizationProfile: user.updateOrganizationProfile,
            updateVolunteerOpportunity: user.updateVolunteerOpportunity,
            location: user.location,
        }
    };
};
const loginFailure = (error) => {
    return {
        type: authConstants.LOGIN_FAILURE,
        payload: error
    };
};

//const logOutRequest = () => ({type: authConstants.LOGOUT_REQUEST});
const logOutSuccess = () => ({ type: authConstants.LOGOUT_SUCCESS });
const logOutFailure = () => ({ type: authConstants.LOGOUT_FAILURE });

const verifyRequest = () => ({ type: authConstants.VERIFY_REQUEST });
// const verifySuccess = () => ({type: authConstants.VERIFY_SUCCESS});

const forgotPasswordSuccess = () => ({ type: authConstants.FORGOT_PASSWORD_SUCCESS, payload: "Un lien de réinitialisation du mot de passe a été envoyé à votre courriel." })//"Courriel envoyé. Veuillez vérifier votre courrel." })
const forgotPasswordError = () => ({ type: authConstants.FORGOT_PASSWORD_FAILED, payload: "Les courriel n'est pas dans nos dossiers." })

export const signIn = ({ email, password }) => dispatch => {
    dispatch(loginRequest())
    return auth
        .signInWithEmailAndPassword(email, password)
        .then(user => {
            if (user.user !== null) {
                db.collection("Staff")
                    .where("authUID", '==', user.user.uid)
                    .get()
                    .then(querySnapshot => {
                        const staff = querySnapshot.docs.map(doc => ({
                            ...doc.data(),
                            id: doc.id
                        }));
                        if (staff[0]) {
                            if (staff[0].isEnable) {
                                user.staffId = staff[0] ? staff[0].id : ''
                                user.organizationId = staff[0] ? staff[0].organizationId : ''
                                user.manageStaff = staff[0] ? staff[0].permission.manageStaff : '0'
                                user.updateOrganizationEvent = staff[0] ? staff[0].permission.updateOrganizationEvent : '0'
                                user.updateOrganizationProfile = staff[0] ? staff[0].permission.updateOrganizationProfile : '0'
                                user.updateVolunteerOpportunity = staff[0] ? staff[0].permission.updateVolunteerOpportunity : '0'
                                user.location = staff[0] ? staff[0].location : []
                                user.uid = user.user.uid
                                dispatch(loginSuccess(user));
                            } else {
                                if (window.location.pathname === "/login") {
                                    dispatch(loginFailure("Compte désactivé."))
                                } else {
                                    dispatch(logOut())
                                }
                            }
                        } else {
                            user.staffId = staff[0] ? staff[0].id : ''
                            user.organizationId = staff[0] ? staff[0].organizationId : ''
                            user.manageStaff = staff[0] ? staff[0].permission.manageStaff : '0'
                            user.updateOrganizationEvent = staff[0] ? staff[0].permission.updateOrganizationEvent : '0'
                            user.updateOrganizationProfile = staff[0] ? staff[0].permission.updateOrganizationProfile : '0'
                            user.updateVolunteerOpportunity = staff[0] ? staff[0].permission.updateVolunteerOpportunity : '0'
                            user.location = staff[0] ? staff[0].location : []
                            user.uid = user.user.uid
                            dispatch(loginSuccess(user));
                        }
                    })
            } else {
                dispatch(loginFailure());
            }
        })
        .catch(error => {
            //alert("Informations d'identification non valides.")
            //dispatch(loginFailure(error.message));
            dispatch(loginFailure("Informations d'identification non valides."));
        });
};

export const logOut = () => dispatch => {
    return auth
        .signOut()
        .then(() => dispatch(logOutSuccess()))
        .catch(() => dispatch(logOutFailure()));
};

export const verifyAuth = () => dispatch => {
    dispatch(verifyRequest());
    return auth
        .onAuthStateChanged(user => {
            if (user !== null) {
                db.collection("Staff")
                    .where("authUID", '==', user.uid)
                    .get()
                    .then(
                        querySnapshot => {
                            const staff = querySnapshot.docs.map(doc => ({
                                ...doc.data(),
                                id: doc.id
                            }));
                            if (staff[0]) {
                                if (staff[0].isEnable) {
                                    user.staffId = staff[0] ? staff[0].id : ''
                                    user.organizationId = staff[0] ? staff[0].organizationId : ''
                                    user.manageStaff = staff[0] ? staff[0].permission.manageStaff : '0'
                                    user.updateOrganizationEvent = staff[0] ? staff[0].permission.updateOrganizationEvent : '0'
                                    user.updateOrganizationProfile = staff[0] ? staff[0].permission.updateOrganizationProfile : '0'
                                    user.updateVolunteerOpportunity = staff[0] ? staff[0].permission.updateVolunteerOpportunity : '0'
                                    user.location = staff[0] ? staff[0].location : []
                                    dispatch(loginSuccess(user));
                                } else {
                                    if (window.location.pathname === "/login") {
                                        dispatch(loginFailure("Compte désactivé."))
                                    } else {
                                        dispatch(logOut())
                                    }
                                }
                            } else {
                                user.staffId = staff[0] ? staff[0].id : ''
                                user.organizationId = staff[0] ? staff[0].organizationId : ''
                                user.manageStaff = staff[0] ? staff[0].permission.manageStaff : '0'
                                user.updateOrganizationEvent = staff[0] ? staff[0].permission.updateOrganizationEvent : '0'
                                user.updateOrganizationProfile = staff[0] ? staff[0].permission.updateOrganizationProfile : '0'
                                user.updateVolunteerOpportunity = staff[0] ? staff[0].permission.updateVolunteerOpportunity : '0'
                                user.location = staff[0] ? staff[0].location : []
                                dispatch(loginSuccess(user));
                            }
                        }
                    )
            } else {
                dispatch(loginFailure());
            }
            // dispatch(verifySuccess());
        });
};

export const forgotPassword = (email) => dispatch => {
    auth.languageCode = 'fr';
    return auth
        .sendPasswordResetEmail(email)
        .then(function () {
            dispatch(forgotPasswordSuccess());
            //alert('Courriel envoyé. Veuillez vérifier votre courrel.')
        }).catch(function (e) {
            dispatch(forgotPasswordError());
            //alert("Les courriel n'est pas dans nos dossiers.")
        })
}

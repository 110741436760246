import React from "react";
import ReactDOM from "react-dom";

// import "./index.css"; // CSS is currently unused 2021-12-14 do not load an empty file.
import App from "./App";

// ensure translations are loaded for every component that will use {t} = useTranslation()
import "./i18n";

ReactDOM.render(<App />, document.getElementById("root"));


import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

export const PublicRoute = ({component: Component, ...rest}) => {
    const isAuthorized = useSelector(state => state.auth.isAuthenticated);
    return (
        <Route {...rest} render={props => (
            isAuthorized ?
                <Redirect to="/"/>
                : <Component {...props} />
        )}/>
    );
};

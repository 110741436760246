import { authConstants } from "../constants/actionTypes";

const initialState = {
    loginError: false,
    logoutError: false,

    isAuthenticated: false,
    isLoading: false,
    user: {},

    forgotPasswordError: false,
    forgotPasswordSuccess: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case authConstants.LOGIN_REQUEST:
            return {
                ...state,
                // isLoggingIn: true,
                loginError: false
            };
        case authConstants.LOGIN_SUCCESS:
            return {
                ...state,
                isLoggingIn: false,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload
            };
        case authConstants.LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isLoggingIn: false,
                isAuthenticated: false,
                loginError: action.payload
            };
        case authConstants.LOGOUT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                user: {}
            };
        case authConstants.LOGOUT_FAILURE:
            return {
                ...state,
                logoutError: true
            };
        case authConstants.VERIFY_REQUEST:
            return {
                ...state,
                isLoading: true,
                verifyingError: false
            };
        case authConstants.VERIFY_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case authConstants.FORGOT_PASSWORD_REQUEST:
            return {
                //isLoading: true,
                forgotPasswordError: false,
                forgotPasswordSuccess: false,
            }
        case authConstants.FORGOT_PASSWORD_SUCCESS:
            return {
                //isLoading: false,
                forgotPasswordSuccess: action.payload,
            }
        case authConstants.FORGOT_PASSWORD_FAILED:
            return {
                //isLoading: false,
                forgotPasswordError: action.payload,
            }
        default:
            return state;
    }
};
